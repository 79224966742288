import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import HeroHeaderImage from "../components/HeroHeaderImage"
import SEO from "../components/seo"

const IndexPage = () => {
  const {
    homeSites,
    homeLojas,
    homeEmail,
    homeProtecao,
    homeDesempenho,
  } = useStaticQuery(graphql`
    query Images {
      homeSites: file(relativePath: { eq: "layout/home-sites.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 652, maxHeight: 326, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeLojas: file(relativePath: { eq: "layout/home-lojas.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 652, maxHeight: 326, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeEmail: file(relativePath: { eq: "layout/home-email.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 213, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeProtecao: file(relativePath: { eq: "layout/home-protecao.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 213, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeDesempenho: file(relativePath: { eq: "layout/home-desempenho.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 427, maxHeight: 213, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />

      <HeroHeaderImage
        heroMode={"is-dark"}
        heroClass={"home"}
        heroSize={"is-small"}
      >
        <div className="container">
          <div className="block is-titulo-hero">
            <div className="columns">
              <div className="column">
                <h1 className="title is-1 is-uppercase">SH2 Host</h1>
                <h2 className="subtitle is-5">
                  Experiência de mercado. Atendimento exclusivo. <br /> A
                  hospedagem certa para a sua empresa.
                </h2>
                <Link
                  to="/hospedagem"
                  className="button is-medium is-warning m-t-md"
                >
                  Conheça
                </Link>
              </div>
            </div>
          </div>
        </div>
      </HeroHeaderImage>

      <section className="container m-t-lg m-b-lg p-l-sm p-r-sm">
        <div className="block">
          <h1 className="title is-3 has-text-centered">
            Soluções completas para o seu negócio
          </h1>
          <p className="has-text-centered p-b-lg">
            Somos uma empresa de hospedagem focada em oferecer atendimento
            exclusivo e soluções personalizadas para os nossos clientes.
            Trabalhamos para ter o melhor desempenho e maior a disponibilidade
            no mercado. Estamos preparados para atender sua empresa e o seu
            projeto.
          </p>
          <div className="columns m-t-md">
            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-image">
                  <figure className="image">
                    <Img fluid={homeEmail.childImageSharp.fluid} alt="E-mail" />
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">E-mail</p>
                  <p>
                    Soluções para e-mails comerciais. Entrega rápida e
                    eficiente.
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-image">
                  <figure className="image">
                    <Img
                      fluid={homeProtecao.childImageSharp.fluid}
                      alt="Protecao"
                    />
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Proteção</p>
                  <p>
                    Estamos constantemente atualizando nossa estrutura de
                    servidores contra ataques.
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-image">
                  <figure className="image">
                    <Img
                      fluid={homeDesempenho.childImageSharp.fluid}
                      alt="Disponibilidade"
                    />
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Desempenho</p>
                  <p>
                    Arquitetura planejada para velocidade dos seus sites e
                    sistemas.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="columns is-mini-banner-home m-t-md">
            <div className="column">
              <figure className="image">
                <Link to="/loja">
                  <Img
                    fluid={homeLojas.childImageSharp.fluid}
                    alt="Loja Virtual"
                  />
                </Link>
              </figure>
            </div>

            <div className="column">
              <figure className="image">
                <Link to="/site">
                  <Img
                    fluid={homeSites.childImageSharp.fluid}
                    alt="Criação de Sites"
                  />
                </Link>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
