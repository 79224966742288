import React from "react"
import PropTypes from "prop-types"

import NavBar from "./Navbar"

import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const HeroHeaderImage = ({
  children,
  heroClass,
  heroMode,
  heroSize,
  navActive,
}) => {
  const { basico, loja, website, home } = useStaticQuery(graphql`
    query {
      basico: file(relativePath: { eq: "layout/hero_background.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2000, maxHeight: 1250, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      loja: file(relativePath: { eq: "layout/hero_loja.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2009, maxHeight: 668, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      website: file(relativePath: { eq: "layout/hero_websites.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1950, maxHeight: 778, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      home: file(relativePath: { eq: "layout/hero_hospedagem.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1904, maxHeight: 822, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  let activeBackground = basico

  if (heroClass === "loja") {
    activeBackground = loja
  }

  if (heroClass === "website") {
    activeBackground = website
  }

  if (heroClass === "home") {
    activeBackground = home
  }

  return (
    <BackgroundImage
      Tag={`section`}
      id={`test`}
      className={`hero ${heroMode} ${heroSize}`}
      fluid={activeBackground.childImageSharp.fluid}
    >
      <div className="hero-head">
        <NavBar navActive={navActive} />
        <div className="hero-body">{children}</div>
      </div>
    </BackgroundImage>
  )
}

HeroHeaderImage.propTypes = {
  children: PropTypes.node,
  heroMode: PropTypes.string,
  heroClass: PropTypes.string,
  heroSize: PropTypes.string,
  navActive: PropTypes.string,
}

export default HeroHeaderImage
